import { useState, useEffect } from 'react';

import { ReactComponent as Close } from "../images/X.svg";
import cap from "../images/sgs_cap.png";

import WalletProtected from '../layout/WalletProtected'
import Wallet from "../pages/Wallet";

export default function ConnectModal({ closeModal }) {
    return (
        <div className="fixed flex flex-wrap justify-center items-center 
        top-0 left-0 bottom-0 -right-0 z-10 
        bg-opacity-60 bg-black backdrop-blur-sm w-screen h-full">


            <div className="flex flex-col fixed justify-center items-center p-5 w-[80%] sm:w-[60%] h-[80%]
             bg-drbg bg-cover rounded-3xl ">

                <div onClick={closeModal}
                    className="flex fixed sm:absolute right-0 top-0 p-3 m-2 
                    justify-center items-center h-14 w-14 rounded-full
                    hover:cursor-pointer transform hover:border-4 ">
                    <Close className="" />
                </div>

                <div className="text-white h-full justify-center text-center items-center">
                    <h1 className="font-pressio-condensed text-4xl sm:text-5xl ">CONNECT WALLET</h1>
                    <img className="w-[75%] max-w-[350px] mx-auto my-10 xs:my-14" src={cap} />

                    <div className='py-10 xs:py-8'>
                        <WalletProtected>
                            <Wallet />
                        </WalletProtected>
                    </div>
                </div>
            </div>
        </div>
    )
}