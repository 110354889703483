import React, { useState, useEffect } from "react";
import * as API from "../utils/utils";
import { useWeb3React } from "@web3-react/core";
import { injected, walletconnect, walletlink } from "../wallet/connectors";
import { Spinner } from "./Spinner";
import Button from "../components/Button";
import cap from "../images/success_cap.png";
import { Country }  from 'country-state-city';

const ConnectorNames = {
    Injected: "Metamask",
    WalletConnect: "WalletConnect",
    WalletLink: "WalletLink",
};

const connectorsByName = {
    [ConnectorNames.Injected]: injected,
    [ConnectorNames.WalletConnect]: walletconnect,
    [ConnectorNames.WalletLink]: walletlink,
};

export default function Form({ token, setIsProcessingModalOpen }) {

    const context = useWeb3React();
    const { connector } = context;
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [shirtSize, setShirtSize] = useState("");
    const [skateSize, setSkateSize] = useState("");
    const [address1, setAddress1] = useState("");
    const [address2, setAddress2] = useState("");
    const [region, setRegion] = useState("");
    const [city, setCity] = useState("");
    const [postalCode, setPostalCode] = useState("");
    const [country, setCountry] = useState("");

    const [submitted, setSubmitted] = useState(false);
    const [failure, setFailure] = useState(false);
    const [isProcessingSubmit, setIsProcessingSubmit] = useState(false);
    const [error, setError] = useState("");

    const getActiveConnector = () => {
        let active;
        Object.keys(connectorsByName).forEach((name) => {
            const currentConnector = connectorsByName[name];
            const connected = currentConnector === connector;
            if (connected) {
                active = name;
            }
        });
        return active;
    };

    const CONTRACT_ADDRESS = "0x00ea2894fe840f105ab99a8f8f75b1f17e94843a";

    const handleSubmit = async (event) => {
        try {
            event.preventDefault();
            setError("");
            setIsProcessingSubmit(true);
            let currentConnector = getActiveConnector();
            await API.signMessagePublic(
                context.account,
                currentConnector,
                context
            ).then(async ({ message, signature }) => {
                if (message && signature) {
                    await API.submitTicketForm(
                        firstName,
                        lastName,
                        email,
                        phoneNumber,
                        shirtSize,
                        skateSize,
                        address1,
                        address2,
                        region,
                        city,
                        postalCode,
                        country,
                        context.account,
                        token,
                        CONTRACT_ADDRESS,
                        message,
                        signature
                    ).then((resp) => {
                        if (resp.success === true) {
                            setFailure(false);
                        } else {
                            setIsProcessingSubmit(false);
                            setFailure(true);
                        }
                    });
                }
            });
        } catch (e) {
            console.error(e);
            setError(e.message);
            setIsProcessingSubmit(false);
            setFailure(true);
        } finally {
            setIsProcessingSubmit(false);
            setSubmitted(true);
            // stop loading?
        }
    };

    const labelClassName = `font-open-sans text-s font-bold`;
    const inputClassName = `font-open-sans w-full p-1 rounded-md mb-2 border text-gray-500`;

    return (
        <div className="w-full ">
            {!isProcessingSubmit && <div
                onClick={() => setIsProcessingModalOpen(false)}
                className='absolute justify-center items-center z-5 flex right-[16px] 
            top-[21px] cursor-pointer h-12 w-12 bg-black/80
            rounded-full hover:bg-black/50'>
                <img className={''} src="/X.svg" alt="close icon" />
            </div>}
            {!submitted ? (
                <div>
                    {!isProcessingSubmit ? (
                        <div className='bg-[#fafafa]  p-10 rounded-xl '>

                            <form onSubmit={handleSubmit}>
                                <h2 className="font-pressio-condensed font-black text-3xl mb-3">Complete Form</h2>

                                <div className="md:grid grid-cols-5 gap-12 lg:gap-20 ">
                                    <div className="col-span-2">
                                        <label>
                                            <p className={labelClassName}>First Name <span className="text-red-600">*</span></p>
                                            <input className={inputClassName}
                                                type="text"
                                                placeholder=""
                                                required
                                                onChange={(e) => setFirstName(e.target.value)}
                                            />
                                        </label>
                                        <label>
                                            <p className={labelClassName}>Last Name <span className="text-red-600">*</span></p>
                                            <input className={inputClassName}
                                                type="text"
                                                placeholder=""
                                                required
                                                onChange={(e) => setLastName(e.target.value)}
                                            />
                                        </label>
                                        <label>
                                            <p className={labelClassName}>Email <span className="text-red-600">*</span></p>
                                            <input className={inputClassName}
                                                type="email"
                                                placeholder=""
                                                required
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                        </label>
                                        <label>
                                            <p className={labelClassName}>Phone Number <span className="text-red-600">*</span></p>
                                            <input className={inputClassName}
                                                type="number"
                                                placeholder=""
                                                required
                                                onChange={(e) => setPhoneNumber(e.target.value)}
                                            />
                                        </label>
                                        <label>
                                            <p className={labelClassName}>Shirt Size <span className="text-red-600">*</span></p>
                                            <select className={inputClassName} defaultValue={''}
                                                onChange={(e) => setShirtSize(e.target.value)} required>
                                                <option value='' disabled hidden>Choose Shirt Size</option>
                                                <option>Small</option>
                                                <option>Medium</option>
                                                <option>Large</option>
                                                <option>X-Large</option>

                                            </select>
                                        </label>

                                        <label>
                                            <p className={labelClassName}>Skate Size <span className="text-red-600">*</span></p>
                                            <select className={inputClassName} defaultValue={''}
                                                onChange={(e) => setSkateSize(e.target.value)} required>
                                                <option value='' disabled hidden>Choose Skate Size</option>
                                                <option>X-Small - 5/6 US</option>
                                                <option>Small -7/8 US</option>
                                                <option>Medium - 9/10 US</option>
                                                <option>Large - 11/12 US</option>
                                            </select>
                                        </label>

                                    </div>
                                    <div className="col-span-3">

                                        <label>
                                            <p className={labelClassName}>Address Line 1 <span className="text-red-600">*</span></p>
                                            <input className={inputClassName}
                                                type="text"
                                                placeholder=""
                                                required
                                                onChange={(e) => setAddress1(e.target.value)}
                                            />
                                        </label>
                                        <label>
                                            <p className={labelClassName}>Address Line 2 (Optional)</p>
                                            <input className={inputClassName}
                                                type="text"
                                                placeholder=""
                                                onChange={(e) => setAddress2(e.target.value)}
                                            />
                                        </label>

                                        <div className="md:grid grid-rows-2 grid-cols-2 gap-x-2">

                                            <label>
                                                <p className={labelClassName}>State / Region <span className="text-red-600">*</span></p>
                                                <input className={inputClassName}
                                                    type="text"
                                                    placeholder=""
                                                    required
                                                    onChange={(e) => setRegion(e.target.value)}
                                                />
                                            </label>
                                            <label>
                                                <p className={labelClassName}>City <span className="text-red-600">*</span></p>
                                                <input className={inputClassName}
                                                    type="text"
                                                    placeholder=""
                                                    required
                                                    onChange={(e) => setCity(e.target.value)}
                                                />
                                            </label>
                                            <label>
                                                <p className={labelClassName}>Postal Code <span className="text-red-600">*</span></p>
                                                <input className={inputClassName}
                                                    type="xtet"
                                                    placeholder=""
                                                    required
                                                    onChange={(e) => setPostalCode(e.target.value)}
                                                />
                                            </label>
                                            <label>
                                                <p className={labelClassName}>Country *</p>
                                                <select className={inputClassName} defaultValue={''}
                                                    onChange={(e) => setCountry(e.target.value)} required>
                                                    <option value='' disabled hidden>Select Country</option>
                                                    {Country.getAllCountries().map((country) => (
                                                        <option key={country.name}>{country.name}</option>
                                                    ))}
                                                </select>
                                            </label>

                                        </div>


                                        <div className='flex justify-center mt-4 mb-4 text-[26px]'>
                                            <Button className='' type='submit'
                                            >
                                                Claim Now
                                            </Button>
                                        </div>
                                    </div>

                                </div>

                            </form>
                        </div>
                    ) : (
                        <div className='bg-[#fafafa] p-10 rounded-xl md:w-96'>
                            <div>
                                <h2 className="font-pressio-condensed font-black text-3xl mb-10">Processing...</h2>
                                <Spinner color="#ebebeb" height={32} width={32} className='' />
                                <div className='mt-10'>
                                    <p className="font-open-sans">Interact with your wallet and sit tight!</p>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            ) : !failure ? (
                <div className='bg-[#fafafa] p-10 rounded-xl md:w-96'>
                    <h2 className="font-pressio-condensed font-black text-3xl mb-10">SUCCESS!</h2>
                    <p className=''>
                        Congratulations {`${firstName}`}, you've claimed a commemorative Drug Receipts x Blading Cup Box Set!
                    </p>
                    <img className="w-[75%] max-w-[350px] mx-auto my-10 xs:my-14" src={cap} />
                    <p className=''>
                        We will send a confirmation email to {`${email}`}
                    </p>
                </div>
            ) : (
                <div className='bg-[#fafafa] p-10 rounded-xl md:w-96'>
                    <h2 className="font-pressio-condensed font-black text-3xl mb-10">TRY AGAIN!</h2>
                    <p className={``}>
                        Error: {error ? error : ""} - Please try again.
                    </p>
                </div>
            )}
        </div>
    )
}