
import { useEffect, useState, useCallback } from "react";
import { useWeb3React } from "@web3-react/core";
import { useNavigate } from "react-router-dom";
import { useQuery } from 'react-query';

import * as API from "../utils/utils";
import Button from "../components/Button";
import Tokens from "../components/Tokens";
import NFTs from "../components/NFTs";
import { WalletLinkConnector } from "@web3-react/walletlink-connector";
import cap from '../images/sgs_cap.png'
import sgs from '../images/sgs.png'
import Header from "../components/Header";
import MainPage from "../components/MainPage";
import ProcessingModal from "../components/ProcessingModal.js";

export default function Claim() {

    const wallet = useWeb3React();
    const [status, setStatus] = useState(""); // "success", "error", "processing"
    const [txHash, setTxHash] = useState("");
    const [isConnected, setIsConnected] = useState(false);
    const [isProcessingModalOpen, setIsProcessingModalOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [tokensToMint, setTokensToMint] = useState([]);
    const [tokensLoading, setTokensLoading] = useState(true);

    const navigate = useNavigate();


    const doClaim = async () => {
        if (tokensToMint.length > 0)
            setIsProcessingModalOpen(true);
    }

    return (
        <div className="h-full w-full relative">
            {isProcessingModalOpen && (
                <ProcessingModal
                    setIsProcessingModalOpen={setIsProcessingModalOpen}
                    token={tokensToMint[0]}
                />
            )}
            <div className="">
                <Header
                    menuItems={['Main Website', 'Opensea Collection']}
                    rightArea={null}
                    showSocial={true}
                    externalLink={true}
                    routes={['https://drugreceipts.com/', 'https://opensea.io/collection/drugreceipts']}
                />

                <MainPage>
                    <div className="flex flex-col w-full justify-center items-center">

                        <div className="flex flex-col md:flex-row w-full mt-10">
                            <div className="text-white md:pr-12 md2:mt-10 lg:mt-28 md:w-1/2 ">
                                <h1 className="font-pressio-condensed text-5xl font-medium mb-2 ">
                                    TRAIT DROP <br />
                                    SPECIAL GRAY SKATES
                                </h1>
                                <p className="font-open-sans text-xl pt-2 font-light">
                                Congratulations, Drug Snuggler! It’s time to get your mother snuggin’ roll on! 
                                As a holder of a Drug Receipts NFT with the “Special Gray Skates” trait you 
                                are eligible to receive one of the super snuggin’ sweet limited edition Drug 
                                Receipts x Blading Cup Box Sets — featuring a pair of Them Skates 909 
                                rollerblades tricked out with Drug Receipts flair!
                                </p>
                            </div>

                            <div className="md:w-1/2">
                                <img src={cap} className='w-full ' />
                            </div>
                        </div>

                        <div className="flex flex-col md:flex-row w-full mt-10">

                            <div className="md:w-1/2 md:pr-12">
                                <img src={sgs} className='w-full ' />
                            </div>

                            <div className="text-white md:ml-10 md2:mt-10 lg:mt-28 md:w-1/2 ">
                                <h1 className="font-pressio-condensed text-5xl font-medium mb-2 ">
                                    CLAIM YOUR<br />
                                    TRAIT DROP
                                </h1>
                                <p className="font-open-sans text-xl pt-2 font-light">
                                Select your eligible NFT (Special Gray Skate or 1 of 1) below and proceed to the 
                                form. If you hold more than one eligible NFT you will have to go through 
                                the process more than once. Only one claim may be processed at a time.
                                </p>

                                <div className="flex items-center flex-wrap w-full h-full pt-4 sm:pt-16">
                                    <div className="absolute top-0 right-1">
                                        {wallet.account}
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="w-full rounded-md pt-4 md:pt-0
                        overflow-x-auto md:overflow-x-hidden ">
                            <NFTs className=''
                                tokensToMint={tokensToMint}
                                setTokensToMint={setTokensToMint}
                                isProcessingModalOpen={isProcessingModalOpen}
                            >
                            </NFTs>
                        </div>

                        <div className='flex justify-center mt-5 mb-24 text-[26px] w-[300px]'>
                            <Button className=''
                                clickFunction={() => { doClaim() }}
                            >
                                Proceed to Form
                            </Button>
                        </div>

                    </div>
                </MainPage>
            </div>








        </div>

    )
}