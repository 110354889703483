import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import { AiFillCloseCircle } from "react-icons/ai";

import * as API from '../utils/utils';
import classNames from "classnames";


export default function NFTs({ tokensToMint, setTokensToMint, isProcessingModalOpen }) {

    const [images, setImages] = useState([]);
    const [selected, setSelected] = useState([]);
    const [unselected, setUnselected] = useState([]);

    const [tokensInWallet, setTokensInWallet] = useState([]);
    const [tokensLoading, setTokensLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState("");
    const [didHaveTokens, setDidHaveTokens] = useState(false);
    const contractAddress = "";
    const wallet = useWeb3React();
    const navigate = useNavigate();

    const getTokensForWallet = useCallback(
        async () => {
            try {
                let tokenIds = await API.retrieveValidTokens(wallet.account);
                let whitelisted = await API.checkWhitelisted(wallet.account);
                if (whitelisted.whitelisted) setDidHaveTokens(true);

                setTokensInWallet(tokenIds);
                setTokensLoading(false);
            } catch (e) {
                console.error(e.message);
                setTokensLoading(false);
                setErrorMessage(e.message);
            }
        }, [wallet.account, navigate]
    );

    useEffect(() => {
        if (!isProcessingModalOpen) { // could change this to form success only (reduce api call) so it happens before closing (can still unselect token on error)
            setTokensToMint([]);
            getTokensForWallet();
        }
    }, [isProcessingModalOpen]);

    useEffect(() => {
        getTokensForWallet();
    }, [tokensToMint])

    const getImageUrl = (tokenId) => {
        try {
            const url = API.getImageUrl(tokenId);
            return url;
        } catch (e) {
            console.error(e.message);
            setTokensLoading(false);
            setErrorMessage(e.message);
        }
    }

    function toggleImageSelected(tokenId) {
        let current = [...tokensToMint];
        if (current.includes(tokenId)) current.pop()
        else if (current.length > 0) return;
        else current.push(tokenId);

        setTokensToMint(current);
    }

    return (
        <div className="bg-black/50 w-full overflow-y-auto rounded-md mb-5 p-3 ">
            {tokensInWallet.length === 0 && (
                <div className="flex items-center justify-center h-full text-center">
                    {tokensLoading
                        ? "Loading..."
                        : errorMessage
                            ? `Error: ${errorMessage}`
                            : didHaveTokens
                                ? "You don't have any tokens left to claim with."
                                : "You don't own any tokens to claim with."}
                </div>
            )}
            {tokensInWallet.length > 0 && (
                <div>
                    <div className="flex flex-wrap flex-row overflow-x-auto
                        gap-2 w-full ">
                        {tokensInWallet && (tokensInWallet).map((token) => (
                            <div key={token}
                                className={'cursor-pointer relative  overflow-hidden min-w-1/4 max-w-[150px]'}
                                onClick={() => toggleImageSelected(token)}>

                                <img src={getImageUrl(token)} alt={`Drug receipt #${token}`}
                                    className={'rounded-md' + classNames({
                                        " border-2 border-white opacity-70 brightness-50":
                                            tokensToMint.includes(token)
                                    })}
                                />
                                <AiFillCloseCircle
                                    className={`absolute top-1 right-1 w-8 h-8 text-white opacity-100
                                        ${tokensToMint.includes(token) ? 'block' : 'hidden'} `} />
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    )
}

// <AssetCard
//     asset={asset}
//     key={`${asset.tokenId}`}
// />