import { ethers } from "ethers";
// import { WalletConnect } from "@web3-react/walletconnect";
import { walletconnect } from "../wallet/connectors";

const CONTRACT_ADDRESS = "0x00ea2894fe840f105ab99a8f8f75b1f17e94843a";
const API_URL = 'https://claim-api.drugreceipts.com' // 'http://localhost:3001';

const getHeaders = () => {
    return {
        headers: {
            "Content-Type": "application/json",
        },
    };
};

const extractBody = async (res) => {
    if (!res.ok) {
        throw new Error(await res.text());
    } else {
        return res.json();
    }
};


let provider, signer;
if (window.ethereum) {
    provider = new ethers.providers.Web3Provider(window.ethereum);
    signer = provider.getSigner();
} else {
    // INFURA FALLBACK RPC HERE
    let fallbackProvider = ethers.getDefaultProvider("homestead", {});
}

export const updateProviderAndSigner = (library) => {
    if (library) {
        provider = new ethers.providers.Web3Provider(library.provider);
    } else {
        provider = new ethers.providers.Web3Provider(window.ethereum);
    }
    signer = provider.getSigner();
};



export const getImageUrl = (tokenId, rand = false) => {
    const url = `https://drugreceipts-compressed-5t931f461b.s3.amazonaws.com/render_${tokenId}/image_${tokenId}_432.png${rand ? '?' + Math.random() : ''}`;
    return url;
}

export const retrieveValidTokens = async (walletAddress) => {
    return await fetch(`${API_URL}/api/validtokens`, {
        method: 'POST',
        ...getHeaders(),
        body: JSON.stringify({ walletAddress })
    })
        .then((response) => extractBody(response))
        .catch((e) => {
            console.error(e);
        });
}


export const checkWhitelisted = async (walletAddress) => {
    // return {whitelisted: true}
    return await fetch(`${API_URL}/api/iswhitelisted`, {
        method: "POST",
        ...getHeaders(),
        body: JSON.stringify({ walletAddress })
    }).then((response) => extractBody(response));
};


export const signMessagePublic = async (walletToAuthenticate, provider, context) => {
    let message = `This signature is to prove that you are the true owner of this wallet.`;
    let signature;

    provider = provider.toLowerCase()

    if (provider === "walletlink") {
        await sleep(400);
    }

    if (provider === "walletconnect" || provider === "walletlink") {
        signature = await context.library.provider.send("personal_sign", [
            ethers.utils.hexlify(ethers.utils.toUtf8Bytes(message)),
            walletToAuthenticate.toLowerCase(),
        ]);
        console.log(`${walletToAuthenticate}`)
    } else {
        signature = await signer.signMessage(message);
    }

    return { message, signature };
};


export const submitTicketForm = async (
    firstName,
    lastName,
    email,
    phoneNumber,
    shirtSize,
    skateSize,
    address1,
    address2,
    region,
    city,
    postalCode,
    country,
    walletAddress,
    tokenId,
    contractAddress,
    message,
    signature,
) => {
    return await fetch(`${API_URL}/api/submitform`, {
        method: "POST",
        ...getHeaders(),
        body: JSON.stringify({
            firstName,
            lastName,
            email,
            phoneNumber,
            shirtSize,
            skateSize,
            address1,
            address2,
            region,
            city,
            postalCode,
            country,
            walletAddress,
            tokenId,
            contractAddress,
            message,
            signature,
        }),
    }).then((response) => extractBody(response));
};


const sleep = async (ms) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve();
        }, ms);
    });
};
