import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import logo from "../images/DRx-Logo-Header.png";
import { FaTwitter, FaInstagram, FaFacebook, FaDiscord } from 'react-icons/fa';
import { GiHamburgerMenu } from 'react-icons/gi';

import Sidebar from "./Sidebar";

const SocialLinks = () => {
  const iconClassName = `inline-flex items-center justify-center text-white text-[17px]
  bg-[#78b2cf] rounded-[50%] leading-[17px] w-[34px] h-[34px]
  transition delay-0 hover:bg-[#5d8aa0] hover:scale-110 duration-200`;

  return (
    <div className="flex items-center content-center h-full gap-1 my-auto ">
      <span>
        <a
          className={iconClassName}
          href="https://discord.gg/SUtQHy2CPM"
          target="_blank"
          rel="noreferrer"
        >
          <FaDiscord />

        </a>
      </span>
      <span>
        <a
          className={iconClassName}
          href="https://twitter.com/drugreceipts"
          target="_blank"
          rel="noreferrer"
        >
          <FaTwitter />
        </a>
      </span>
      <span>
        <a
          className={iconClassName}
          href="https://www.instagram.com/drugreceipts/"
          target="_blank"
          rel="noreferrer"
        >
          <FaInstagram />

        </a>
      </span>
      <span>
        <a
          className={iconClassName}
          href="https://web.facebook.com/drugreceipts?_rdc=1&amp;_rdr"
          target="_blank"
          rel="noreferrer"
        >
          <FaFacebook />

        </a>
      </span>
    </div>
  )
};

const MenuItems = ({ menuProps, externalLink, routes }) => {
  const navigate = useNavigate();

  let items = menuProps.map((e, i) => {
    return (
      <div key={e}>
        {
          externalLink ? (
            <a href={routes[i]} target='_blank' >
              <div
                className="text-xl font-pressio-condensed px-0 sm:px-[13px] py-[8px] text-[20px] 
              font-medium uppercase cursor-pointer text-dr_gray hover:opacity-60">{e}
              </div>
            </a>
          ) : (
            <Link to={routes[i]} target='_self'>
              <div
                className="text-xl font-pressio-condensed px-[13px] py-[8px], text-[20px] 
              font-medium uppercase cursor-pointer text-dr_gray hover:opacity-60">
                {e}
              </div>
            </Link>
          )
        }
      </div>
    );
  });
  return items;
}


export default function Header({ menuItems, rightArea, showSocial, externalLink, routes }) {
  const navigate = useNavigate();

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const toggleSidebar = () => {
    setIsSidebarOpen((prevState) => !prevState);
  };


  return (
    <div className="">
      <div className="bg-white px-5 ">
        <div className="flex max-w-5xl items-center justify-between py-3 mx-auto sm:px-0">
          <div className="py-2">
            <Link to='/'>
              <img src={logo} className="w-[168px] hover:cursor-pointer" />
            </Link>
          </div>
          <div className="hidden px-10 sm:flex justify-evenly grow items-center">
            <MenuItems menuProps={menuItems} externalLink={externalLink} routes={routes} />
          </div>
          <div className="hidden sm:block">{rightArea && rightArea}</div>
          {showSocial ? (
            <div className="hidden sm:block">
              <SocialLinks />
            </div>
          ) : null}
          <div className="sm:hidden">
            {!isSidebarOpen &&
              <button className={`inline-flex items-center justify-center text-dr_gray
              text-[25px] transition delay-0 hover:scale-110 duration-200`} onClick={toggleSidebar}>
                <GiHamburgerMenu />
              </button>}
          </div>
          {isSidebarOpen &&
            <div>
              <Sidebar 
              toggleSidebar={toggleSidebar} 
              isSidebarOpen={isSidebarOpen}
              menuItems={<MenuItems menuProps={menuItems} externalLink={externalLink} routes={routes} />}
              socialIcons={<SocialLinks />}
              />
            </div>}
        </div>
      </div>
    </div>
  );
}
