import React from "react";
import Form from "./Form";

export default function ProcessingModal({
    setIsProcessingModalOpen,
    token,
}) {

    return (
        <div className='fixed z-50 top-0 bottom-0 left-0 right-0 flex justify-center
                        bg-[#000000b2] backdrop-blur-md items-center'>
            <div className='fixed max-w-[1050px] rounded-xl z-3 scale-[0.7] sm:scale-[0.8] md:scale-90'>
                
                <Form token={token} setIsProcessingModalOpen={setIsProcessingModalOpen} />
            </div>
        </div>
    );
};