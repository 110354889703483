import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import MainPage from "../components/MainPage";
import Header from "../components/Header";
import Button from "../components/Button";
import ConnectModal from "../components/ConnectModal";

import logo from "../images/DRx-logo.png";
// import rex from "../images/Murdered-Out-Cap.png";
import cap from "../images/sgs_cap.png";

export default function ConnectWallet({ dimensions }) {

    const navigate = useNavigate();
    const [connectModalOpen, setConnectModalOpen] = useState(false);

    const closeModal = () => {
        setConnectModalOpen(false);
    };

    const openModal = () => {
        setConnectModalOpen(true)
    }

    return (
        <div>
            <Header
                menuItems={['Main Website', 'Opensea Collection']}
                rightArea={null}
                showSocial={true}
                externalLink={true}
                routes={['https://drugreceipts.com/', 'https://opensea.io/collection/drugreceipts']}
            />
            {connectModalOpen && <ConnectModal closeModal={closeModal} />}
            <MainPage>
                <div className="flex flex-col h-full mx-auto mt-12 sm:pb-12 ">

                    <div className="flex items-center justify-center w-full h-full gap-8 ">
                        <div className="w-full lg:w-1/2 my-10">

                            <img className="w-[182px] my-8 " src={logo} />
                            <h1 className="font-pressio-condensed text-white text-5xl font-medium">
                                SPECIAL GRAY SKATES CLAIM
                            </h1>
                            <p className="font-open-sans text-white text-2xl font-light">
                                Connect your wallet to claim your Drug Receipts x Blading Cup Box Set.
                            </p>

                            <div className="flex gap-4 my-10 text-[26px] mt-[40px] w-[300px]">
                                <Button clickFunction={openModal}
                                    className=''
                                >
                                    Connect Wallet
                                </Button>
                            </div>
                        </div>


                        {dimensions.width > 900 && (
                            <img
                                src={cap}
                                className="w-1/2"
                                alt="Rex Character"
                                width={470}
                                height={505}
                            />
                        )}
                    </div>
                </div>
            </MainPage>
        </div>

    );
}

